.businessList{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.sortby-dropdown {
    outline: none;
    border-radius: 0px;
    border-bottom: 2px solid black;
    text-align: center;
    padding: 4px 28px 4px;
}

.rating-filter {
    margin-top: 15px;
}

.rating-filter svg {
    display: inline;
    margin-bottom: 5px;
}