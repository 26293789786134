.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  padding: 0px;

  .logo-container {
    height: 100%;
    width: 30 px;
    padding: 0px;
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 0px 10px;
      &:hover {
        cursor: pointer;

        transform: scale(1.1);
        transition: transform 6s cubic-bezier(0.13, 0.7, 0.94, 0.36);
      }
    }

    .search-icon {
      height: 100%;
      width: 20px;
      padding: 0px 0px 0px 30px;
    }
  }
}
