@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control {
  margin-bottom: 1rem;
}

.form-control input,
.form-control label, 
.form-control textarea 
{
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control .error-text {
  margin: 0;
  font-size: small;
}

.form-control input,
.form-control textarea
 {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.form-control select
 {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 21rem;
  max-width: 100%;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  column-gap: 1.5rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  min-width: 15rem;
  /* width: 20rem; */
  flex:1;
}

.social-media-container {
  flex: 0 1 auto !important;
  /* flex-shrink: 0 !important; */
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input,
.invalid textarea {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus,
.invalid textarea:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

.fb-icon {
  color: #3B5998;
  margin-right: 0.5em;
}

.instagram-icon {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  color: white;
  border-radius: 28%;
  margin-right: 0.5em;
  /* margin-left: 0.5em; */
}

.pinterest-icon {
  color: #E60023;
  margin-right: 0.5em;
  /* margin-left: 0.5em; */
}

.other-icon {
  margin-right: 0.5em;
}

.service-actions:hover {
  cursor: pointer;
}

input {
  outline: none;
}