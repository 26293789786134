.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px;
  //font-size: 50px;
  .background-image {
    width: 100vw;
    height: 30vw;
    background-position: center;
    background-size: cover;
  }
}
