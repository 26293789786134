.sign-in-and-sign-up {
  width: 1000rem;
  margin: 30rem auto;
  flex: row;
  display: flex;
  justify-content: space-between;
}

.home {
  text-align: center;
  font-size: 1.7rem;
}
