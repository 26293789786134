.category-item {
  min-width: 15%;
  height: 240px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    & .background-image {
      transform: scale(1.5);
      transition: transform 6s cubic-bezier(0.13, 0.7, 0.94, 0.36);
    }
    & .content {
      opacity: 0.9;
    }
  }

  // &.large{
  //  height: 380px;
  // }
  // &:first-child {
  //   margin-right: 8px;
  // }

  // &:last-child {
  //   margin-left: 7.5px;
  // }
  .background-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .content {
    height: 50%;
    width: fit-content;
    max-width: 100%;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: white;
    opacity: 0.7;
    position: absolute;

    .title {
      font-weight: bold;
      margin-bottom: 6px;
      color: #4a4a4a;
    }
  }
}
